
import {computed, defineComponent, ref} from "vue";
import {IonBadge, IonButton, IonCol, IonGrid, IonItem, IonLabel, IonRow, isPlatform} from "@ionic/vue";
import {Order, ProducedOrder} from "@/domain/model/order/order.model";
import {formatDate} from "@/domain/helper/date.helper";


export default defineComponent({
  name: "OrderRecordListItemComponent",
  components: {
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonGrid,
    // IonButton,
    IonBadge,
  },
  props: {
    order: null,
  },
  setup(prop: any) {
    const orderProp = ref<any>(prop.order)
    const isTablet = computed<boolean>(() => !isPlatform('desktop'));
    // const lastProducedOrder: ProducedOrder = orderProp.value.producedOrder[orderProp.value.producedOrder.length - 1];
    // let date;
    // if (!lastProducedOrder)
    //   date = orderProp.value.deliveryDate;
    // else
    //   date = lastProducedOrder.date;

    // console.log(orderProp.value.deliveryDate);
    const endDate = formatDate(orderProp.value.deliveryDate, "DD-MM-YYYY");
    const producedAmount = computed(() => orderProp.value.producedAmount);//orderProp.value.producedOrder.reduce((accumulator: number, itemB: ProducedOrder) => accumulator + Number(itemB.amount), 0));
    const goOrderResume = () => {
      return 1;
    }

    return {
      isTablet,
      goOrderResume,
      orderProp,
      endDate,
      producedAmount,
    };
  }
})
