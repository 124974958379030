import {Order, ProducedOrder, STATE} from "@/domain/model/order/order.model";
import {uuid4} from "@capacitor/core/dist/esm/util";
import {Material} from "@/domain/model/material/material.model";
import {emptyMaterial} from "@/domain/service/model/material.service";
import moment from 'moment';


export function emptyProducedOrder() {
    const emptyProducedOrder: ProducedOrder = {
        id: uuid4(),
        orderId: '',
        amount: 0,
        date: new Date().getTime(),
        comments: '',
        scrapAmount: 0,
        state: 'active'
    }
    return emptyProducedOrder;
}

export function emptyOrder() {
    const emptyOrder: Order = {
        id: uuid4(),
        material: emptyMaterial(),
        expectedAmount: 900,
        scrapAmount: 90,
        producedScrapAmount: 0,
        producedAmountWithScrap: 0,
        pendingAmount: 900,
        producedOrder: [],
        receivedDate: moment().unix(),
        deliveryDate: moment().unix(),
        state: 'finalized',
    }
    return emptyOrder;
}