import {Material} from "@/domain/model/material/material.model";
import {useStore} from "vuex";
import {Order} from "@/domain/model/order/order.model";
import _ from "lodash";
import {useStock} from "@/domain/service/model/stock.service";
import {uuid4} from "@capacitor/core/dist/esm/util";
import store from "@/domain/store";

export function actualOrdersAmount(material: Material): number {
    const {calculatePending} = useStock();
    if (material.type === 'part')
        return 0;
    const allOrders: Order[] = store.getters.allActiveOrders;
    const ordersOfActualKit = _.filter(allOrders, (order: Order) => order.material.ref === material.ref);
    return ordersOfActualKit.reduce((accumulator: number, itemB: Order) => accumulator + calculatePending(itemB), 0);
}

export function emptyMaterial(): Material{
    return {
        id: uuid4(),
        ref: '',
        name: '',
        type: 'production',
        components: [],
        scrapRatio: 0.01,
        group: '',
    }
}

