
import {computed, defineComponent, h, ref, watch} from "vue";
import {
  IonButton,
  IonCard, IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonItem, IonItemDivider,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSearchbar, isPlatform, onIonViewWillEnter
} from "@ionic/vue";
import OrderRecordListItemComponent from "@/app/view/order/list/recordList/OrderRecordListItemComponent.vue";
import {useStore} from "vuex";
import {Order} from "@/domain/model/order/order.model";
import {emptyOrder} from "@/domain/service/model/order.service";
import _, {map} from "lodash";
import {useOrderApi} from "@/domain/service/api/order.api";
import {NButton, NDataTable, NSpace, NTag} from "naive-ui";
import {useMaterialApi} from "@/domain/service/api/material.api";
import {useStockApi} from "@/domain/service/api/stock.api";
import {Material} from "@/domain/model/material/material.model";
import {formatDate} from "@/domain/helper/date.helper";

export default defineComponent({
  name: "OrderRecordListPage",
  components: {
    OrderRecordListItemComponent,
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonCard,
    IonCardContent,
    IonList,
    IonSearchbar,
    IonItemDivider,
    NSpace,
    NDataTable,
  },
  setup() {
    const store = useStore();
    const searchWord = ref<string>('');
    const isTablet = computed<boolean>(() => !isPlatform('desktop'));

    const data = ref<any>([]);
    const searchOrder = (order: Order, word: string): boolean => order.material?.ref.toLowerCase().includes(word.toLowerCase()) ||
        order.material?.name.toLowerCase().includes(word.toLowerCase())
        || order.material?.group.toLowerCase().includes(word.toLowerCase());
    const filterOrder = (orders: Order[], word: string): Order[] => _.filter(orders, (orderNode: Order) => searchOrder(orderNode, word));
    const sortOrder = (orders: Order[]): Order[] => _.orderBy(orders, ['deliveryDate'],[ 'desc']);
    const ordersList = computed<Order[]>(() => sortOrder(filterOrder(store.getters.allFinalizedOrders, searchWord.value)));

    const onTest = () => {
      const newOrder: Order = emptyOrder();
      store.commit('saveOrder', newOrder);
    }
    const replaceData = () => {
      data.value =
          map(ordersList.value, (orderNode: Order) => {
            return {
              id: orderNode.id,
              material: orderNode.material,
              ref: orderNode.material.ref,
              deliveredDate: formatDate(orderNode.deliveryDate, "DD-MM-YYYY"),
              expectedAmount: orderNode.expectedAmount,
              producedAmount: orderNode.producedAmountWithScrap,
            };
          });
    };


    watch(ordersList, () => replaceData(), {immediate: true});


    onIonViewWillEnter(async () => {
      await useOrderApi().fetchAll();
    });

    return {
      data,
      columns: [
        {
          title: "Referencia producto",
          key: "ref",
          sorter: 'default'
        },
        {
          title: "Entregado",
          key: "deliveredDate",
          sorter: 'default'
        },
        {
          title: "Cantidad esperada",
          key: "expectedAmount",
          sorter: 'default'
        },
        {
          title: "Cantidad producida",
          key: "producedAmount",
          sorter: 'default'
        },
        {
          title: "Estado",
          key: "state",
          render(row: any): any {
            return h(
                NTag,
                {
                  type: 'success',
                },
                {default: () => 'Finalizado'},
            );
          },
        },
      ],

      ordersList,
      searchWord,
      onTest,
      isTablet,
    };
  }
})
